var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-navigation-drawer',{attrs:{"color":"#f4f5fa","clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-2",staticStyle:{"color":"#9ca3a5","font-size":"12px"}},[_vm._v(" UNOCRM | v3.0 "),_c('v-icon',[_vm._v(" mdi-settings")])],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"pl-0 pr-4 pt-3 sidebar",attrs:{"dense":""}},[_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Dashboard")])],1)],1)]}}])},[_c('span',[_vm._v("Pantalla de inicio")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('activityLog')),expression:"permissions('activityLog')"}]},[_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/reports"}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v(" mdi-chart-bar")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Reportes")])],1)],1)]}}])},[_c('span',[_vm._v("Graficas mensuales y por día")])])],1),_c('v-divider',{staticClass:"ma-4"}),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/calendar","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-calendar-multiple")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Calendario")])],1)],1)]}}])},[_c('span',[_vm._v("Agenda de cotizaciónes en seguimiento")])]),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/activities","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-checkbox-marked-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Actividades")])],1)],1)]}}])},[_c('span',[_vm._v("Lista de seguimientos de prospectos activos")])]),_c('v-divider',{staticClass:"ma-4"}),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/clients","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v(" mdi-account-multiple")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Empresas | Contactos")])],1)],1)]}}])},[_c('span',[_vm._v("Lista de empresas a las que se les ha cotizado/vendido")])]),_c('v-divider',{staticClass:"ma-4"}),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/quotations","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v(" mdi-file-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cotizaciones")])],1)],1)]}}])},[_c('span',[_vm._v("Prospectos de venta")])]),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/sales","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-currency-usd")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ventas")])],1)],1)]}}])},[_c('span',[_vm._v("Lista de ventas cerradas")])]),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/canceled","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("m mdi-close-circle-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Canceladas")])],1)],1)]}}])},[_c('span',[_vm._v("Cotizaciones que no se cerraron por algun motivo")])]),_c('v-divider',{staticClass:"ma-4"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('activityLog')),expression:"permissions('activityLog')"}]},[_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/activity-log","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-clipboard-text")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Bitacoras")])],1)],1)]}}])},[_c('span',[_vm._v("Registro de altas y cambios")])])],1)],1)],1),_c('v-app-bar',{staticClass:"elevation-0 px-0",attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"app":"","color":"#f4f5fa","dark":""}},[_c('v-icon',{staticClass:"mr-5",attrs:{"color":"grey"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_vm._v(" mdi-menu")]),_c('img',{staticStyle:{"height":"24px"},attrs:{"src":"https://unocrm.mx/wp-content/uploads/2020/08/unocrm_logo-1.svg"}}),_c('v-spacer'),_c('v-text-field',{staticClass:"hidden-sm-and-down mt-6",attrs:{"label":"Search here for reports analytics and help","prepend-icon":"mdi-magnify","rounded":"","light":""}}),_c('v-btn',{staticClass:"hidden-sm-and-down pa-0",attrs:{"icon":"","to":"/","link":""}},[_c('v-icon',{attrs:{"color":"#707073"}},[_vm._v("mdi-apps")])],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"icon":"","min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"overlap":"","color":"#e25104"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(" 9 ")]},proxy:true}],null,true)},[_c('v-icon',{attrs:{"color":"#707073"}},[_vm._v("mdi-bell")])],1)],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{attrs:{"to":"/notas","link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" mdi-clipboard-text")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Notas ")])],1)],1)],1)],1)],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-avatar',{attrs:{"size":"32px"}},[_c('img',{attrs:{"src":"https://unocrm.mx/wp-content/uploads/2020/04/cropped-Recurso-1.png"}})]),_c('span',{staticClass:"hidden-sm-and-down",staticStyle:{"color":"#707073","margin-left":"10px","text-transform":"initial","letter-spacing":"0px","font-size":"16px","font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.currentUser.name)+" ")]),_vm._v(" "),_c('v-icon',{attrs:{"color":"#707073"}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{attrs:{"link":"","to":"/settings"}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" mdi-cog")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ajustes")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.logout}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-power")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cerrar Sesión")])],1)],1)],1)],1)],1)],1),_c('v-main',{staticClass:"pa-0",staticStyle:{"background-color":"#f4f5fa"}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('router-view',{on:{"closeDrawer":_vm.closeDrawer}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }